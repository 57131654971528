import { baseUrl, useGetDataDumpsQuery } from '@/app/services/nucleus';
import { Heading, HStack, Link, Button } from '@chakra-ui/react';
import { createColumnHelper } from '@tanstack/react-table';
import DataTableSearchAndFiltering from '../common/table/DataTableSearchAndFiltering';
import { useSelector } from 'react-redux';
import { selectDataDumpSearchStatus, selectDataDumpSortBy, selectDataDumpSortDirection } from './dataDumpSlice';
import { ERROR, LOADING } from '@/utils/constants';

const DataDumpPage = () => {
  const columnHelper = createColumnHelper();
  const searchStatus = useSelector(selectDataDumpSearchStatus);
  const sortBy = useSelector(selectDataDumpSortBy);
  const sortDirection = useSelector(selectDataDumpSortDirection);

  const { data: dataDumps, isLoading, isError, error: dataDumpsError } = useGetDataDumpsQuery();

  const dataDumpsColumns = [
    columnHelper.accessor('name', {
      header: 'Name',
      cell: (info) => (info.getValue() ? info.getValue() : 'None'),
    }),
    columnHelper.accessor('', {
      header: 'Download File',
      cell: (info) => {
        const dumps = info.row.original;
        return (
          <HStack>
            <Link aria-label="download file" href={`${baseUrl}/v1/dumps/${dumps.id}/download`} isExternal>
              <Button size="sm" variant="outline">
                Download
              </Button>
            </Link>
          </HStack>
        );
      },
    }),
  ];

  return (
    <>
      <Heading as="h4" size="md" fontWeight="bold" color="brand.900">
        Data Dumps
      </Heading>
      <DataTableSearchAndFiltering
        title="Data Dumps"
        originalData={dataDumps}
        searchStatus={searchStatus}
        sortBy={sortBy}
        isSearchLoading={searchStatus === LOADING}
        isSearchError={searchStatus === ERROR}
        isDataLoading={isLoading}
        isDataError={isError || dataDumpsError}
        dataErrorMsg={dataDumpsError?.data?.detail}
        dataErrorTitle={'Error fetching data dumps'}
        dataColumns={dataDumpsColumns}
        searchColumns={['name']}
        sortDirection={sortDirection}
      ></DataTableSearchAndFiltering>
    </>
  );
};

export default DataDumpPage;
