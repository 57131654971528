import { createSlice } from '@reduxjs/toolkit';
import { nucleusApi } from '@/app/services/nucleus';

const initialState = {
  searchStatus: '',
  sortBy: 'name',
  sortDirection: 'asc',
};

export const dataDumpSlice = createSlice({
  name: 'dataDump',
  initialState,
  reducers: {
    setSearchStatus: (state, { payload }) => {
      state.searchStatus = payload;
    },
    setSortBy: (state, { payload }) => {
      state.sortBy = payload;
    },
    setSortDirection: (state, { payload }) => {
      state.sortDirection = payload;
    },
  },
});

export const { setDataDumps, setSearchStatus, setSortBy, setSortDirection } = dataDumpSlice.actions;

export const selectDataDumpSearchStatus = (state) => state.dumps.searchStatus;
export const selectDataDumpSortBy = (state) => state.dumps.sortBy;
export const selectDataDumpSortDirection = (state) => state.dumps.sortDirection;

export default dataDumpSlice.reducer;
